import { ChainId, JSBI, Percent, Token, WETH } from '@uniswap/sdk';
import { AbstractConnector } from '@web3-react/abstract-connector';
import {
  injected,
  walletconnect,
  walletlink,
} from '../connectors';
import MetamaskIcon from 'assets/images/metamask.png';
import BlockWalletIcon from 'assets/images/blockwalletIcon.svg';
import cypherDIcon from 'assets/images/cypherDIcon.png';
import BitKeepIcon from 'assets/images/bitkeep.png';
import CoinbaseWalletIcon from 'assets/images/coinbaseWalletIcon.svg';
import WalletConnectIcon from 'assets/images/walletConnectIcon.svg';

const WETH_ONLY: ChainTokenList = {
  [ChainId.SAPPHIRE]: [WETH[ChainId.SAPPHIRE]],
  [ChainId.EMERALD]: [WETH[ChainId.SAPPHIRE]],
};

// TODO: Remove this constant when supporting multichain
export const MATIC_CHAIN = ChainId.SAPPHIRE;

export enum TxnType {
  SWAP,
  ADD,
  REMOVE,
}

export const GlobalConstMultiChain = {
  [ChainId.SAPPHIRE]: {
    addresses: {
      ROUTER_ADDRESS: {
        [ChainId.SAPPHIRE]: '0xfF77692Da88c82d5C7Bed4D786A2FcD014AB2e6E',
      },
      ZERO_ADDRESS: '0x0000000000000000000000000000000000000000',
      FACTORY_ADDRESS: '0x006FC7c0b6f6E3317da0922905266357B7E76a2D',
      WROSE: "0x8Bc2B030b299964eEfb5e1e0b36991352E56D2D3",
      WETH: '0xfc6b18d694F2D137dB762B152736Ba098F9808d9',
      USDC: '0x2c2E3812742Ab2DA53a728A09F5DE670Aba584b6',
      USDT: '0xE48151964556381B33f93E05E36381Fd53Ec053E',
      WBTC: '0xE9533976C590200E32d95C53f06AE12d292cFc47',
      BNB: '0xe95E3a9f1a45B5EDa71781448F6047d7B7e31cbF',
      MATIC: '0xa349005a68FA33e8DACAAa850c45175bbcD49B19',
      OCEAN: '0x39d22B78A7651A76Ffbde2aaAB5FD92666Aca520',
    },
  }
}

export const GlobalConst = {
  blacklists: {
    TOKEN_BLACKLIST: [''],
    PAIR_BLACKLIST: [''],
  },
  addresses: {
    [ChainId.SAPPHIRE]: {
      ROUTER_ADDRESS: {
        [ChainId.SAPPHIRE]: '0xfF77692Da88c82d5C7Bed4D786A2FcD014AB2e6E',
        [ChainId.EMERALD]: '0xfF77692Da88c82d5C7Bed4D786A2FcD014AB2e6E',
      },
      ZERO_ADDRESS: '0x0000000000000000000000000000000000000000',
      FACTORY_ADDRESS: '0x006FC7c0b6f6E3317da0922905266357B7E76a2D',
      WROSE: "0x8Bc2B030b299964eEfb5e1e0b36991352E56D2D3",
      WETH: '0xfc6b18d694F2D137dB762B152736Ba098F9808d9',
      USDC: '0x2c2E3812742Ab2DA53a728A09F5DE670Aba584b6',
      USDT: '0xE48151964556381B33f93E05E36381Fd53Ec053E',
      WBTC: '0xE9533976C590200E32d95C53f06AE12d292cFc47',
      BNB: '0xe95E3a9f1a45B5EDa71781448F6047d7B7e31cbF',
      MATIC: '0xa349005a68FA33e8DACAAa850c45175bbcD49B19',
      OCEAN: '0x39d22B78A7651A76Ffbde2aaAB5FD92666Aca520',
    },
    [ChainId.EMERALD]: {
      ROUTER_ADDRESS: {
        [ChainId.SAPPHIRE]: '0xfF77692Da88c82d5C7Bed4D786A2FcD014AB2e6E',
        [ChainId.EMERALD]: '0xfF77692Da88c82d5C7Bed4D786A2FcD014AB2e6E',
      },
      ZERO_ADDRESS: '0x0000000000000000000000000000000000000000',
      FACTORY_ADDRESS: '0x006FC7c0b6f6E3317da0922905266357B7E76a2D',
      WROSE: "0x8Bc2B030b299964eEfb5e1e0b36991352E56D2D3",
      WETH: '0xfc6b18d694F2D137dB762B152736Ba098F9808d9',
      USDC: '0x2c2E3812742Ab2DA53a728A09F5DE670Aba584b6',
      USDT: '0xE48151964556381B33f93E05E36381Fd53Ec053E',
      WBTC: '0xE9533976C590200E32d95C53f06AE12d292cFc47',
      BNB: '0xe95E3a9f1a45B5EDa71781448F6047d7B7e31cbF',
      MATIC: '0xa349005a68FA33e8DACAAa850c45175bbcD49B19',
      OCEAN: '0x39d22B78A7651A76Ffbde2aaAB5FD92666Aca520',
    }
  },
  utils: {
    QUICK_CONVERSION_RATE: 1000,
    ONEDAYSECONDS: 60 * 60 * 24,
    DQUICKFEE: 0.04,
    DQUICKAPR_MULTIPLIER: 0.01,
    ROWSPERPAGE: 10,
    FEEPERCENT: 0.003,
    BUNDLE_ID: '1',
    PROPOSAL_LENGTH_IN_DAYS: 7, // TODO this is only approximate, it's actually based on blocks
    NetworkContextName: 'NETWORK',
    INITIAL_ALLOWED_SLIPPAGE: 50, // default allowed slippage, in bips
    DEFAULT_DEADLINE_FROM_NOW: 60 * 20, // 20 minutes, denominated in seconds
    BIG_INT_ZERO: JSBI.BigInt(0),
    ONE_BIPS: new Percent(JSBI.BigInt(1), JSBI.BigInt(10000)), // one basis point
    BIPS_BASE: JSBI.BigInt(10000),
    // used to ensure the user doesn't send so much ETH so they end up with <.01
    MIN_ETH: JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)), // .01 ETH
    BETTER_TRADE_LINK_THRESHOLD: new Percent(
      JSBI.BigInt(75),
      JSBI.BigInt(10000),
    ),
    // the Uniswap Default token list lives here
    // we add '' to remove the possibility of nulls
    DEFAULT_TOKEN_LIST_URL: process.env.REACT_APP_TOKEN_LIST_DEFAULT_URL + '',
    DEFAULT_LP_FARMS_LIST_URL:
      process.env.REACT_APP_STAKING_LIST_DEFAULT_URL + '',
    DC_LP_FARMS_LIST_URL: process.env.REACT_APP_STAKING_LIST_DC_URL + '',
    DEFAULT_DUAL_FARMS_LIST_URL:
      process.env.REACT_APP_DUAL_STAKING_LIST_DEFAULT_URL + '',
    DEFAULT_SYRUP_LIST_URL: process.env.REACT_APP_SYRUP_LIST_DEFAULT_URL + '',
    ANALYTICS_TOKENS_COUNT: 200,
    ANALYTICS_PAIRS_COUNT: 400,
  },
  analyticChart: {
    ONE_MONTH_CHART: 1,
    THREE_MONTH_CHART: 2,
    SIX_MONTH_CHART: 3,
    ONE_YEAR_CHART: 4,
    ALL_CHART: 5,
    CHART_COUNT: 60, //limit analytics chart items not more than 60
  },
  farmIndex: {
    LPFARM_INDEX: 0,
    DUALFARM_INDEX: 1,
    DC_LPFARM_INDEX: 2,
  },
  walletName: {
    METAMASK: 'Metamask',
    CYPHERD: 'CypherD',
    BLOCKWALLET: 'BlockWallet',
    BITKEEP: 'BitKeep',
    INJECTED: 'Injected',
    SAFE_APP: 'Gnosis Safe App',
    ARKANE_CONNECT: 'Venly',
    // Portis: 'Portis',
    WALLET_LINK: 'Coinbase Wallet',
    WALLET_CONNECT: 'WalletConnect',
  },
};

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  CYPHERD: {
    connector: injected,
    name: GlobalConst.walletName.CYPHERD,
    iconName: cypherDIcon,
    description: 'CypherD browser extension.',
    href: null,
    color: '#E8831D',
  },
  METAMASK: {
    connector: injected,
    name: GlobalConst.walletName.METAMASK,
    iconName: MetamaskIcon,
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  BLOCKWALLET: {
    connector: injected,
    name: GlobalConst.walletName.BLOCKWALLET,
    iconName: BlockWalletIcon,
    description: 'BlockWallet browser extension.',
    href: null,
    color: '#1673ff',
  },
  BITKEEP: {
    connector: injected,
    name: GlobalConst.walletName.BITKEEP,
    iconName: BitKeepIcon,
    description: 'BitKeep browser extension.',
    href: null,
    color: '#E8831D',
  },
  INJECTED: {
    connector: injected,
    name: GlobalConst.walletName.INJECTED,
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  // Portis: {
  //   connector: portis,
  //   name: GlobalConst.walletName.Portis,
  //   iconName: PortisIcon,
  //   description: 'Login using Portis hosted wallet',
  //   href: null,
  //   color: '#4A6C9B',
  //   mobile: true,
  // },
  WALLET_LINK: {
    connector: walletlink,
    name: GlobalConst.walletName.WALLET_LINK,
    iconName: CoinbaseWalletIcon,
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5',
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: GlobalConst.walletName.WALLET_CONNECT,
    iconName: WalletConnectIcon,
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true,
  },
};

export const GlobalValue = {
  percents: {
    ALLOWED_PRICE_IMPACT_LOW: new Percent( // used for warning states
      JSBI.BigInt(100),
      GlobalConst.utils.BIPS_BASE,
    ), // 1%
    ALLOWED_PRICE_IMPACT_MEDIUM: new Percent(
      JSBI.BigInt(300),
      GlobalConst.utils.BIPS_BASE,
    ), // 3%
    ALLOWED_PRICE_IMPACT_HIGH: new Percent(
      JSBI.BigInt(500),
      GlobalConst.utils.BIPS_BASE,
    ), // 5%
    PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: new Percent( // if the price slippage exceeds this number, force the user to type 'confirm' to execute
      JSBI.BigInt(1000),
      GlobalConst.utils.BIPS_BASE,
    ), // 10%
    BLOCKED_PRICE_IMPACT_NON_EXPERT: new Percent( // for non expert mode disable swaps above this
      JSBI.BigInt(1500),
      GlobalConst.utils.BIPS_BASE,
    ), // 15%
  },
  tokens: {
    [ChainId.SAPPHIRE]: {
      MATIC: WETH[ChainId.SAPPHIRE],
      COMMON: {
        EMPTY: new Token(
          ChainId.SAPPHIRE,
          '0x0000000000000000000000000000000000000000',
          0,
          'EMPTY',
          'EMPTY',
        ),
        WROSE: new Token(
          ChainId.SAPPHIRE,
          GlobalConst.addresses[ChainId.SAPPHIRE].WROSE,
          18,
          'WROSE',
          'Wrapped Rose',
        ),
        USDC: new Token(
          ChainId.SAPPHIRE,
          GlobalConst.addresses[ChainId.SAPPHIRE].USDC,
          18,
          'USDC',
          'USDC Coin',
        ),
        WETH: new Token(
          ChainId.SAPPHIRE,
          GlobalConst.addresses[ChainId.SAPPHIRE].WETH,
          18,
          'WETH',
          'Wrapped ETH',
        ),
        USDT: new Token(
          ChainId.SAPPHIRE,
          GlobalConst.addresses[ChainId.SAPPHIRE].USDT,
          6,
          'USDT',
          'USDT Coin',
        ),
        WBTC: new Token(
          ChainId.SAPPHIRE,
          GlobalConst.addresses[ChainId.SAPPHIRE].WBTC,
          8,
          'WBTC',
          'WBTC',
        ),
        BNB: new Token(
          ChainId.SAPPHIRE,
          GlobalConst.addresses[ChainId.SAPPHIRE].BNB,
          18,
          'BNB',
          'BNB Coin',
        ),
        MATIC: new Token(
          ChainId.SAPPHIRE,
          GlobalConst.addresses[ChainId.SAPPHIRE].MATIC,
          18,
          'Matic',
          'Matic Coin',
        ),
        OCEAN: new Token(
          ChainId.SAPPHIRE,
          GlobalConst.addresses[ChainId.SAPPHIRE].OCEAN,
          18,
          'Ocean',
          'Ocean Coin',
        ),
      }
    },
    [ChainId.EMERALD]: {
      MATIC: WETH[ChainId.SAPPHIRE],
      COMMON: {
        EMPTY: new Token(
          ChainId.SAPPHIRE,
          '0x0000000000000000000000000000000000000000',
          0,
          'EMPTY',
          'EMPTY',
        ),
        WROSE: new Token(
          ChainId.SAPPHIRE,
          GlobalConst.addresses[ChainId.SAPPHIRE].WROSE,
          18,
          'WROSE',
          'Wrapped Rose',
        ),
        USDC: new Token(
          ChainId.SAPPHIRE,
          GlobalConst.addresses[ChainId.SAPPHIRE].USDC,
          18,
          'USDC',
          'USDC Coin',
        ),
        WETH: new Token(
          ChainId.SAPPHIRE,
          GlobalConst.addresses[ChainId.SAPPHIRE].WETH,
          18,
          'WETH',
          'Wrapped ETH',
        ),
        USDT: new Token(
          ChainId.SAPPHIRE,
          GlobalConst.addresses[ChainId.SAPPHIRE].USDT,
          6,
          'USDT',
          'USDT Coin',
        ),
        WBTC: new Token(
          ChainId.SAPPHIRE,
          GlobalConst.addresses[ChainId.SAPPHIRE].WBTC,
          8,
          'WBTC',
          'WBTC',
        ),
        BNB: new Token(
          ChainId.SAPPHIRE,
          GlobalConst.addresses[ChainId.SAPPHIRE].BNB,
          18,
          'BNB',
          'BNB Coin',
        ),
        MATIC: new Token(
          ChainId.SAPPHIRE,
          GlobalConst.addresses[ChainId.SAPPHIRE].MATIC,
          18,
          'Matic',
          'Matic Coin',
        ),
        OCEAN: new Token(
          ChainId.SAPPHIRE,
          GlobalConst.addresses[ChainId.SAPPHIRE].OCEAN,
          18,
          'Ocean',
          'Ocean Coin',
        ),
      }
    }
  },
};

export const GlobalData = {
  bases: {
    // used to construct intermediary pairs for trading
    BASES_TO_CHECK_TRADES_AGAINST: {
      ...WETH_ONLY,
      [ChainId.SAPPHIRE]: [
        ...WETH_ONLY[ChainId.SAPPHIRE],
        GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.USDC,
      ],
    },
    // Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these tokens.
    CUSTOM_BASES: { [ChainId.SAPPHIRE]: undefined, [ChainId.EMERALD]: undefined },
    // used for display in the default list when adding liquidity
    SUGGESTED_BASES: {
      // ...WETH_ONLY,
      [ChainId.SAPPHIRE]: [
        ...WETH_ONLY[ChainId.SAPPHIRE],
        GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.USDC,
        GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.USDT,
        GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.WBTC,
        GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.BNB,
        GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.OCEAN,
      ],
      [ChainId.EMERALD]: [
        ...WETH_ONLY[ChainId.SAPPHIRE],
        GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.USDC,
        GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.USDT,
        GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.WBTC,
        GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.BNB,
        GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.OCEAN,
      ],
    },
    // used to construct the list of all pairs we consider by default in the frontend
    BASES_TO_TRACK_LIQUIDITY_FOR: {
      // ...WETH_ONLY,
      [ChainId.SAPPHIRE]: [
        ...WETH_ONLY[ChainId.SAPPHIRE],
        GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.USDC,
        // GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.MB2,
      ],
      [ChainId.EMERALD]: [
        ...WETH_ONLY[ChainId.SAPPHIRE],
        GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.USDC,
        // GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.MB2,
      ],
    },
  },
  pairs: {
    PINNED_PAIRS: {
      [ChainId.SAPPHIRE]: [
        [WETH[ChainId.SAPPHIRE], GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.USDC],
        [WETH[ChainId.SAPPHIRE], GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.USDT],
        [WETH[ChainId.SAPPHIRE], GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.WBTC],
      ],
      [ChainId.EMERALD]: [
        [WETH[ChainId.SAPPHIRE], GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.USDC],
        [WETH[ChainId.SAPPHIRE], GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.USDT],
        [WETH[ChainId.SAPPHIRE], GlobalValue.tokens[ChainId.SAPPHIRE].COMMON.WBTC],
      ],
    },
  },
  analytics: {
    CHART_DURATIONS: [
      GlobalConst.analyticChart.ONE_MONTH_CHART,
      GlobalConst.analyticChart.THREE_MONTH_CHART,
      GlobalConst.analyticChart.SIX_MONTH_CHART,
      GlobalConst.analyticChart.ONE_YEAR_CHART,
      GlobalConst.analyticChart.ALL_CHART,
    ],
    CHART_DURATION_TEXTS: ['1M', '3M', '6M', '1Y', 'All'],
  },
};

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[];
};

export interface WalletInfo {
  connector?: AbstractConnector;
  name: string;
  iconName: string;
  description: string;
  href: string | null;
  color: string;
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
}
