import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// remove all frames in a interval of 100ms
setInterval(() => {
  const frames = document.getElementsByTagName('iframe');
  for (let i = 0; i < frames.length; i++) {
    frames[i].remove();
  }
}, 500);
