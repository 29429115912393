import React, { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useWalletModalToggle } from 'state/application/hooks';
import {
  isTransactionRecent,
  useAllTransactions,
} from 'state/transactions/hooks';
import { TransactionDetails } from 'state/transactions/reducer';
import { shortenAddress, addMaticToMetamask, isSupportedNetwork } from 'utils';
import useENSName from 'hooks/useENSName';
import { WalletModal } from 'components';
import { useActiveWeb3React } from 'hooks';
import { ReactComponent as ThreeDotIcon } from 'assets/images/ThreeDot.svg';
import { ReactComponent as LightIcon } from 'assets/images/LightIcon.svg';
import WalletIcon from 'assets/images/WalletIcon.png';
import NewTag from 'assets/images/NewTag.png';
import SparkleLeft from 'assets/images/SparkleLeft.svg';
import SparkleRight from 'assets/images/SparkleRight.svg';
import SparkleTop from 'assets/images/SparkleTop.svg';
import SparkleBottom from 'assets/images/SparkleBottom.svg';
import 'components/styles/Header.scss';
import lizardLogo from "assets/images/lizard-new-logo-transparent.png";

import { useTranslation } from 'react-i18next';

const newTransactionsFirst = (a: TransactionDetails, b: TransactionDetails) => {
  return b.addedTime - a.addedTime;
};

const Header: React.FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { account } = useActiveWeb3React();
  const { ethereum } = window as any;
  const { ENSName } = useENSName(account ?? undefined);
  const [openDetailMenu, setOpenDetailMenu] = useState(false);
  const theme = useTheme();
  const allTransactions = useAllTransactions();
  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions);
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst);
  }, [allTransactions]);

  const pending = sortedRecentTransactions
    .filter((tx: any) => !tx.receipt)
    .map((tx: any) => tx.hash);
  const confirmed = sortedRecentTransactions
    .filter((tx: any) => tx.receipt)
    .map((tx: any) => tx.hash);
  const tabletWindowSize = useMediaQuery(theme.breakpoints.down('sm'));
  const mobileWindowSize = useMediaQuery(theme.breakpoints.down('xs'));
  const toggleWalletModal = useWalletModalToggle();
  const menuItems = [
    {
      link: '/swap',
      text: t('swap'),
      id: 'swap-page-link',
    },
    {
      link: '/pools',
      text: t('pool'),
      id: 'pools-page-link',
      isNew: false,
    },
    // {
    //   link: '/liz-bridge',
    //   text: t('lizBridge'),
    //   id: 'liz-bridge-page-link',
    //   isNew: true,
    // },
    {
      link: '/ph',
      text: "Privacy hub (Soon)",
      id: 'privacy-hub',
    },
    {
      link: '/farm',
      text: "Farm (Soon)",
      id: 'farm-page-link',
    },
    {
      link: 'https://cbridge.celer.network/42262/1/WETH',
      text: 'Celer Bridge',
      id: 'bridge-page-link',
      // isNew: true,
      external: true,
    },
    // {
    //   link: '/dragons',
    //   text: t('dragonLair'),
    //   id: 'dragons-page-link',
    // },
    // {
    //   link: '/convert',
    //   text: t('convert'),
    //   id: 'convert-quick',
    // },
    // {
    //   link: '/analytics',
    //   text: t('analytics'),
    //   id: 'analytics-page-link',
    //   isNew: true,
    // },
  ];

  const outLinks: any[] = [
    // {
    //   link: '/',
    //   text: 'Governance',
    // },
    // {
    //   link: '/',
    //   text: 'Docs',
    // },
    // {
    //   link: '/',
    //   text: 'For Developers',
    // },
    // {
    //   link: '/',
    //   text: 'Help & Tutorials',
    // },
    // {
    //   link: '/',
    //   text: 'Knowledge Base',
    // },
    // {
    //   link: '/',
    //   text: 'News',
    // },
  ];

  return (
    <Box className='header'>
      <WalletModal
        ENSName={ENSName ?? undefined}
        pendingTransactions={pending}
        confirmedTransactions={confirmed}
      />
      <Box className='textLizard'>
        LIZARD
      </Box>
      {!tabletWindowSize && (
        <Box className='mainMenu'>
          <div className="prevMenuItem"></div>
          {menuItems.map((val, index) =>
            val.external ? (
              <a
                href={val.link}
                target='_blank'
                rel='noreferrer'
                key={index}
                id={val.id}
                className={`menuItem ${pathname.indexOf(val.link) > -1 ? 'active' : ''
                  }
              `}
              >
                <small>{val.text}</small>
                {val.isNew && (
                  <>
                    <img src={NewTag} alt='new menu' width={46} />
                    <img
                      className='menuItemSparkle menuItemSparkleLeft'
                      src={SparkleLeft}
                    />
                    <img
                      className='menuItemSparkle menuItemSparkleRight'
                      src={SparkleRight}
                    />
                    <img
                      className='menuItemSparkle menuItemSparkleBottom'
                      src={SparkleBottom}
                    />
                    <img
                      className='menuItemSparkle menuItemSparkleTop'
                      src={SparkleTop}
                    />
                  </>
                )}
              </a>
            ) : (
              <Link
                to={val.link}
                key={index}
                id={val.id}
                className={`menuItem ${pathname.indexOf(val.link) > -1 ? 'active' : ''
                  }
              `}
              >
                <small>{val.text}</small>
                {val.isNew && (
                  <>
                    <img src={NewTag} alt='new menu' width={46} />
                    <img
                      className='menuItemSparkle menuItemSparkleLeft'
                      src={SparkleLeft}
                    />
                    <img
                      className='menuItemSparkle menuItemSparkleRight'
                      src={SparkleRight}
                    />
                    <img
                      className='menuItemSparkle menuItemSparkleBottom'
                      src={SparkleBottom}
                    />
                    <img
                      className='menuItemSparkle menuItemSparkleTop'
                      src={SparkleTop}
                    />
                  </>
                )}
              </Link>
            ),
          )}
          {/* <Box display='flex' className='menuItem'>
            <ThreeDotIcon />
            <Box
              position='absolute'
              top={32}
              left={0}
              width={209}
              paddingTop={10}
            >
              <Box className='subMenu'>
                {outLinks.map((item, ind) => (
                  <a href={item.link} key={ind}>
                    <small>{item.text}</small>
                  </a>
                ))}
              </Box>
            </Box>
          </Box> */}
        </Box>
      )}
      {tabletWindowSize && (
        <Box className='mobileMenuContainer'>
          <Box className='mobileMenu'>
            {menuItems.slice(0, 4).map((val, index) =>
              val.external ? (
                <a
                  href={val.link}
                  target='_blank'
                  rel='noreferrer'
                  key={index}
                  className={
                    pathname.indexOf(val.link) > -1 ? 'active' : 'menuItem'
                  }
                >
                  <small>{val.text}</small>
                </a>
              ) : (
                <Link
                  to={val.link}
                  key={index}
                  className={
                    pathname.indexOf(val.link) > -1 ? 'active' : 'menuItem'
                  }
                >
                  <small>{val.text}</small>
                </Link>
              ),
            )}
            <Box className='flex menuItem'>
              <ThreeDotIcon
                onClick={() => setOpenDetailMenu(!openDetailMenu)}
              />
              {openDetailMenu && (
                <Box className='subMenuWrapper'>
                  <Box className='subMenu'>
                    {menuItems.slice(4, menuItems.length).map((val, index) => (
                      <Link
                        to={val.link}
                        key={index}
                        className='menuItem'
                        onClick={() => setOpenDetailMenu(false)}
                      >
                        <small>{val.text}</small>
                      </Link>
                    ))}
                    {outLinks.map((item, ind) => (
                      <a
                        href={item.link}
                        key={ind}
                        onClick={() => setOpenDetailMenu(false)}
                      >
                        <small>{item.text}</small>
                      </a>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
      {/* 300 */}
      <Box className='walletInfo'>
        <Box className='headerIconWrapper'>
          <Box className='styledPollingDot' />
          <LightIcon />
        </Box>
        {account && (!ethereum || isSupportedNetwork(ethereum)) ? (
          <Box
            id='web3-status-connected'
            className='accountDetails'
            onClick={toggleWalletModal}
          >
            <p>{shortenAddress(account)}</p>
            <img src={WalletIcon} alt='Wallet' />
          </Box>
        ) : (
          <Box
            className={`connectButton ${ethereum && !isSupportedNetwork(ethereum)
              ? 'bg-error'
              : 'bg-primary'
              }`}
            onClick={() => {
              if (!ethereum || isSupportedNetwork(ethereum)) {
                toggleWalletModal();
              }
            }}
          >
            {ethereum && !isSupportedNetwork(ethereum)
              ? t('wrongNetwork')
              : t('connectWallet')}
            {ethereum && !isSupportedNetwork(ethereum) && (
              <Box className='wrongNetworkWrapper'>
                <Box className='wrongNetworkContent'>
                  <small>{t('switchWalletToPolygon')}</small>
                  <Box mt={2.5} onClick={addMaticToMetamask}>
                    {t('switchPolygon')}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        )}
        <img className='lizard-logo' src={lizardLogo} alt="Lizard logo" />
      </Box>
    </Box>
  );

};

export default Header;
