export const getTokenLogoURL = (address) => {
  const logoExtensions = ['.png'];
  return logoExtensions.map((ext) => {
    try {
      const image = require(`../assets/tokenLogo/${address.toLowerCase()}${ext}`);
      return image;
    } catch (e) {
      return 'error';
    }
  });
};
