const addMaticToMetamask: () => void = () => {
  const { ethereum } = window as any;
  if (ethereum) {
    ethereum
      .request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: process.env.REACT_APP_HEX_NETWORK,
            // chainName: 'Sapphire ParaTime',
            // rpcUrls: [process.env.REACT_APP_NETWORK_URL],
            // iconUrls: [
            //   'https://assets.coingecko.com/coins/images/279/large/ethereum.png',
            // ],
            // blockExplorerUrls: [process.env.REACT_APP_SCAN_BASE_URL],
            // nativeCurrency: {
            //   name: 'TEST',
            //   symbol: 'TEST',
            //   decimals: 18,
            // },
          },
        ],
      })
      .catch((error: any) => {
        if (error.code === 4001) {
          console.log('We can encrypt anything without the key.');
        } else {
          console.error(error);
        }
      });
  }
};

export default addMaticToMetamask;
