import React, { lazy, Suspense } from 'react';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  ThemeProvider as MuiThemeProvider,
  CssBaseline,
} from '@material-ui/core';
import { Provider } from 'react-redux';
// import { GelatoProvider } from '@gelatonetwork/limit-orders-react';
import store from 'state';
// const DragonPage = lazy(() => import('./pages/DragonPage'));
// const LandingPage = lazy(() => import('./pages/LandingPage'));
// const FarmPage = lazy(() => import('./pages/FarmPage'));

import { PageLayout } from 'layouts';
import { getLibrary } from 'utils';
import StyledThemeProvider from 'theme/index';
import { Web3ReactManager, Popups } from 'components';
import { GlobalConst } from 'constants/index';
import ApplicationUpdater from 'state/application/updater';
// import TransactionUpdater from 'state/transactions/updater';
import ListsUpdater from 'state/lists/updater';
import MulticallUpdater from 'state/multicall/updater';
// import DualFarmUpdater from 'state/dualfarms/updater';
// import SyrupUpdater from 'state/syrups/updater';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Background from 'layouts/Background';
import { useRecurrentData } from 'state/multicall/hooks';
import './i18n';
import { mainTheme } from './custom-theme';

const PoolsPage = lazy(() => import('./pages/PoolsPage'));
const SwapPage = lazy(() => import('./pages/SwapPage'));
const LizBridgePage = lazy(() => import('./pages/LizBridgePage'));

const Web3ProviderNetwork = createWeb3ReactRoot(
  GlobalConst.utils.NetworkContextName,
);

const ThemeProvider: React.FC = ({ children }) => {
  const theme = mainTheme;

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

const Providers: React.FC = ({ children }) => {
  return (
    <div>
      <Suspense fallback={<Background fallback={true} />}>
        <ThemeProvider>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </Suspense>
    </div>
  );
};

function Updaters() {
  useRecurrentData();
  return (
    <>
      <ApplicationUpdater />
      {/* <TransactionUpdater /> */}
      <ListsUpdater />
      <MulticallUpdater />
    </>
  );
}

const App: React.FC = () => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Provider store={store}>
          <Updaters />
          <Providers>
            <Popups />
            <StyledThemeProvider>
              <Web3ReactManager>
                <Switch>
                  <Route exact path='/swap'>
                    <PageLayout>
                      <SwapPage />
                    </PageLayout>
                  </Route>
                  <Route exact path='/pools'>
                    <PageLayout>
                      <PoolsPage />
                    </PageLayout>
                  </Route>
                  <Route exact path='/liz-bridge'>
                    <PageLayout>
                      <LizBridgePage />
                    </PageLayout>
                  </Route>
                  {/* <Route exact path='/farm'>
                    <PageLayout>
                      <FarmPage />
                    </PageLayout>
                  </Route> */}
                  <Route path='*'>
                    <Redirect to='/swap' />
                  </Route>
                </Switch>
              </Web3ReactManager>
            </StyledThemeProvider>
          </Providers>
        </Provider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  );
};

export default App;
